@import  '../../node_modules/bootstrap/scss/bootstrap.scss';
.label-bold{
    font-weight: bold;
    font-size: larger;
    padding-top: 1em;
}
h1.post-title{
    font-weight: 800;
}
h2{
    font-weight: 600;
    margin-bottom: 2rem;
}
h3{
    font-weight: bold;
    margin-bottom: 1.5rem;
}
h4{
    font-weight: 600;
    margin-bottom: 1.0rem;
}
h5{
    font-weight: 600;
}
h6{
    font-weight: 600;
}

.form-label{
    font-weight: bold;
}

