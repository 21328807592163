.hide-text{
  max-height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: spec;
  padding: 0.25rem 0.25rem;
  background-color: white;
}
.hide-text * {
  font-size: 1.0em !important;
  line-height: 1.1em;
}

.show-text{
  height:auto;
}

header.masthead{
  margin-bottom: 1rem;
}

.anchor-break {

  /* These are technically the same, but use both */
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;

  -ms-word-break: break-all !important;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all !important;
  /* Instead use this non-standard one: */
  word-break: break-word !important;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

